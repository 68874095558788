// extracted by mini-css-extract-plugin
export var base = "typography-module--base--YNU-U";
export var blockQuote = "typography-module--blockQuote--5m2Da";
export var large = "typography-module--large--AmsMG";
export var micro = "typography-module--micro--X+Sud";
export var paragraph = "typography-module--paragraph---AmWK";
export var responsiveTitle1 = "typography-module--responsiveTitle1--b0m8q";
export var responsiveTitle2 = "typography-module--responsiveTitle2--7PM74";
export var responsiveTitle3 = "typography-module--responsiveTitle3--Z-BZR";
export var responsiveTitle4 = "typography-module--responsiveTitle4--gvegn";
export var small = "typography-module--small--3rkeD";
export var title1 = "typography-module--title1--Dr3Pn";
export var title2 = "typography-module--title2--t6snJ";
export var title3 = "typography-module--title3--KcjXr";