import * as React from "react";
import Layout from "../components/layout";

import { graphql } from "gatsby";
import {
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
  mapEdgesToNodes,
} from "../lib/helpers";
import BlogPostPreviewList from "../components/sanity/blog-post-preview-list";
import Container from "../components/sanity/container";
import GraphQLErrorList from "../components/sanity/graphql-error-list";
import BlogPostPreview from "../components/sanity/blog-post-preview";

import * as styles from "../components/sanity/post-list-style.css";
import { buildImageObj, cn, getBlogUrl } from "../lib/helpers";
import { Link } from "gatsby";
import PortableText from "../components/sanity/portableText";
import { format } from "date-fns";
import { imageUrlFor } from "../lib/image-url";
import { responsiveTitle3 } from "../components/sanity/typography.module.css";
import BannerImage from "../components/master/BannerImage";
import heroImage from "../assets/about/vision-mssion-values-hero.jpg";
import ContentWrapper from "../components/master/ContentWrapper";
import PageTitle from "../components/master/PageTittle";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query NewsEventsPageQuery {
    posts: allSanityNewspage(
      limit: 6
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`;

const NewsEventsPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  return (
    <Layout>
      <BannerImage imagePath={heroImage} altText="..." />

      <div className="container">
        <ContentWrapper sidebar={true} narrow={true}>
          <div className={"content"}>
            <PageTitle title="News and Events" />

            <div className="row">
              {postNodes &&
                postNodes.map((post) => (
                  <div key={getBlogUrl(post.publishedAt, post.slug.current)} className="post-item">
                    <Link
                      className="col-xs-12"
                      to={getBlogUrl(post.publishedAt, post.slug.current)}
                      style={{ textDecoration: "none" }}
                    >
                      <div className={styles.leadMediaThumb}>
                        {post.mainImage && post.mainImage.asset && (
                          <img
                            src={imageUrlFor(buildImageObj(post.mainImage))
                              .width(250)
                              .height(200)
                              .auto("format")
                              .url()}
                            alt={post.mainImage.alt}
                          />
                        )}
                      </div>
                      <div className={styles.text}>
                        <h3>{post.title}</h3>
                        {post._rawExcerpt && (
                          <div className={styles.excerpt}>
                            {post._rawExcerpt[0].children[0].text && (
                              <p>
                                {post._rawExcerpt[0].children[0].text.length >=
                                100
                                  ? post._rawExcerpt[0].children[0].text.substr(
                                      0,
                                      100
                                    ) + "..."
                                  : post._rawExcerpt[0].children[0].text}
                              </p>
                            )}

                            {/* <PortableText blocks={post._rawExcerpt} /> */}
                          </div>
                        )}
                        
                      </div>
                    </Link>
                  </div>
                ))}
            </div>
          </div>
        </ContentWrapper>
      </div>
    </Layout>
  );
};

export default NewsEventsPage;
